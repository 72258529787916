import styled, { css } from "styled-components";

type ModalProps = {
  isOpen?: boolean;
};

type ImageProps = {
  src: string;
};

type MessageProps = {
  useAnim?: boolean;
  hasIcon?: boolean;
  padding?: string;
};

export const Bg = styled.div<ModalProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1002;
  ${(props) =>
    props.isOpen
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;
export const ErrorBg = styled.div<ModalProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1002;
  ${(props) =>
    props.isOpen
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;
export const Container = styled.div<ModalProps>`
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 14px 16px;
  width: calc(100% - 32px);
  max-height: 95vh;
  max-width: 640px;
  padding-top: 31px;
  overflow: scroll;
  z-index: 1003;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  text-align: center;
  ${(props) =>
    props.isOpen
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;
export const ModalRewardContainer = styled.div<ModalProps>`
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  max-height: 85vh;
  min-height: 480px;
  z-index: 1002;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 14px 16px;
  width: calc(100% - 32px);
  text-align: center;
  max-width: 640px;
  padding-top: 31px;
  ${(props) =>
    props.isOpen
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;
export const Title = styled.p<{ textsize?: "sm" | "lg" }>`
  font-size: ${(props) => (props.textsize === "sm" ? "16px" : "20px")};
  font-weight: bold;
  text-align: center;
  font-family: "Noto Sans JP", sans-serif;
  word-wrap: break-word;
  max-height: 100px;
  white-space: pre-wrap;
  overflow-y: auto;
  line-height: 32px;
`;
export const SubTitle = styled.p`
  font-size: 16px;
  text-align: center;
  line-height: 1em;
`;
export const Image = styled.img<ImageProps>`
  width: 50%;
  height: auto;
  margin: 0 auto;
`;
export const RegisterCompleteImageArea = styled.div`
  width: 94%;
  height: 50%;
  padding: 6% 0;
  background-color: #fff8d3;
  margin: 0 auto;
`;
export const RegisterCompleteSubTitle = styled.div`
  font-size: 16px;
  padding: 0;
`;
export const RegisterCompleteTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;
`;
export const RegisterCompleteImage = styled.img<ImageProps>`
  width: 90%;
  height: auto;
  margin: 0 auto;
`;
export const Message = styled.div<MessageProps>`
  white-space: pre-wrap;
  font-size: 16px;
  padding: ${(props) =>
    props.padding ? props.padding : "20px 20px 20px 20px"};
  margin-top: ${(props) => (props.hasIcon ? "32px" : 0)};
  font-family: "Noto Sans JP", sans-serif;
  word-wrap: break-word;
  ${(props) =>
    props.useAnim === true
      ? css`
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          margin: 0 0 30px 0;
          color: #0271ff;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          line-height: 1.5em;
          word-wrap: break-word;
          opacity: 0;
          animation: 0.5s ease-out 1s fade-in forwards;
        `
      : css``}
`;
export const CloseBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
export const RedrawContainer = styled.div`
  position: relative;
  width: 254px;
  height: 247px;
  margin: 0 auto 35px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AnimationBg = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 254px;
  transform: translateX(-50%);
`;
export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  animation: 0.5s ease-out 0s fade-in;
`;
export const IconCheck = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  margin: 40px 45px 0 0;
  opacity: 0;
  animation: 0.5s ease-out 0.75s fade-in forwards;
`;

export const CouponNotice = styled.div`
  background-color: #eaf3ff;
  border-radius: 5px;
  height: 45px;
  font-size: 14px;
  padding: 5px 25px;
  margin: 30px 6px 20px;
`;

export const CouponTermOfUseWrapper = styled.div`
  padding: 15px 25px;
  background-color: #f5f5f5;
  margin: 10px -15px;
  height: 15vh;
  overflow-y: auto;
`;

export const CouponTermOfUse = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: left;
`;

export const CouponBulletList = styled.ul`
  padding-left: 20px;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  text-align: left;
  max-height: 120px;
  overflow-y: auto;
`;

export const CouponBulletListItem = styled.li`
  position: relative;
`;

export const StyledBarCode = styled.div`
  justify-content: center;
  margin: 10px 0;
  svg {
    max-width: 328px;
  }
`;
export const QRContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PointExchangeButton = styled.div<{ used: boolean }>`
  position: relative;
  margin: 20px 6px;
  padding: 10px 0;
  ${(props) =>
    props.used
      ? css`
          background-color: #999999;
          border-radius: 5px;
        `
      : css``}
`;

export const PointExchangeUsed = styled.div`
  position: absolute;
  bottom: 20px;
  left: 25%;
  width: 50%;
  text-align: center;
  padding: 10px 0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bolder;
  color: #000000;
  background-color: #eeeeee;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
`;

export const ModalRewardUsed = styled.div`
  background-color: #999999;
  height: 80px;
  border-radius: 8px;
  font-size: 20px;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  margin: 16px 10px 14px 10px;
`;

export const BtnContainer = styled.div`
  margin: 30px 0;
`;

export const ModalIcon = styled.div`
  margin-top: 33px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 46px;
  margin-bottom: 10px;
`;
