import React from "react";
import { useParams } from "react-router";
import { Clock } from "../../../../common/icons/Clock";
import {
  formatDateTime,
  isFutureStartDate,
  isWithinPeriod,
} from "../../../../common/utils/date";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { settingStore } from "../../../template/store/setting";
import { Modal } from "../../../ui";
import { Campaign } from "../campaign.type";
import { useCampaign } from "../hooks/useCampaign";
import {
  CampaignButtonContainer,
  CampaignCard,
  CampaignCardContainer,
  CampaignCardImage,
  CampaignCardText,
  CampaignDetailLink,
  CampaignJoinButton,
} from "../style/campaign-card";

type Props = {
  campaign: Campaign;
  isLogin: boolean;
};

export const CampaignCardComponent: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { tenant_code } = useParams();
  const isLogin = localStorage.getItem("t__mm_access_token") !== null;
  const themeColor = settingStore((store) => store.theme_color);
  const { modalProps, handleJoin } = useCampaign(
    props.campaign,
    tenant_code,
    isLogin,
  );

  return (
    <CampaignCardContainer isPromoted={props.campaign.is_promoted}>
      <CampaignCard
        onClick={() => {
          navigate(`/campaign/${props.campaign.id}`);
          sessionStorage.setItem("scrollPosition", window.scrollY.toString());
        }}
        isPromoted={props.campaign.is_promoted}
      >
        <CampaignCardImage
          src={
            props.campaign.campaign_banner_url ||
            "/image/dummy_banner_image.png"
          }
          alt="sample01"
          width="736"
          height="345"
          loading="lazy"
          decoding="async"
        />
        <CampaignCardText>
          <Clock style={{ marginRight: 5, verticalAlign: "middle" }} />
          {isFutureStartDate(props.campaign?.implementation_period_start)
            ? formatDateTime(props.campaign.implementation_period_start) +
              "から"
            : formatDateTime(props.campaign.implementation_period_end) + "まで"}
        </CampaignCardText>

        <CampaignButtonContainer isLogin={props.isLogin}>
          {props.isLogin &&
            isWithinPeriod(
              props.campaign.implementation_period_start,
              props.campaign.implementation_period_end,
            ) && (
              <CampaignJoinButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleJoin();
                }}
                color={themeColor}
              >
                キャンペーンに参加
              </CampaignJoinButton>
            )}
          <CampaignDetailLink
            color={themeColor}
            isLogin={props.isLogin}
            isDouble={isWithinPeriod(
              props.campaign.implementation_period_start,
              props.campaign.implementation_period_end,
            )}
            onClick={() => {
              navigate(`/campaign/${props.campaign.id}`);
              sessionStorage.setItem(
                "scrollPosition",
                window.scrollY.toString(),
              );
            }}
          >
            詳細
          </CampaignDetailLink>
        </CampaignButtonContainer>
      </CampaignCard>
      <Modal {...modalProps} />
    </CampaignCardContainer>
  );
};
